import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'
import {
  IOrderChildEnrollmentClass,
  IOrderChildEnrollmentClassRegistration
} from 'src/types/order-child-enrollment-class'

export class OrderChildEnrollmentClassService extends CRUDService<IOrderChildEnrollmentClass> {
  protected apiPath = '/api/app/order-child-enrollment-class'

  createRegistrationClass(request: IOrderChildEnrollmentClassRegistration) {
    return instanceApi.post<any>(`${this.apiPath}/submit-registration`, request)
  }
}
